import * as React from 'react'
import { Button, IconButton } from '@toasttab/buffet-pui-buttons'
import { CloseIcon } from '@toasttab/buffet-pui-icons'
import { AutoSuggestTextInput } from '@toasttab/buffet-pui-lookup'
import { TextInput } from '@toasttab/buffet-pui-text-input'

import { SpaVersion } from '@local/types'
import { ToastImport } from './importMapApi'
import { CopyLink } from './CopyLink'
import { useImportMap } from './useImportMap'
import { useSpaVersions } from './useSpaVersions'
import { getTokens, sortBy } from './utils'

type ImportMapMutations = ReturnType<typeof useImportMap>['mutations']
interface OverrideFormProps extends ToastImport, ImportMapMutations {}

// provides partial match against version, which is the name of the branch for an adhoc
// sorts exact matches to the top of the list
const makeFilterItemsFn = (getSpaAssetUrl: (item: SpaVersion) => string) => {
  return (items: SpaVersion[], searchTerm: string | undefined) => {
    if (!searchTerm) return items

    const term = searchTerm.toLowerCase().trim()

    const tokens = getTokens(term)

    if (tokens.length === 0) return items

    const filteredItems = items.filter((item) => {
      const assetUrl = getSpaAssetUrl(item).toLowerCase()
      const version = item.version.toLowerCase()

      return tokens.every((str) => version.includes(str)) || assetUrl === term
    })

    return sortBy(filteredItems, (item) => {
      const assetUrl = getSpaAssetUrl(item).toLowerCase()
      const version = item.version.toLowerCase()

      if (version === term) return 0
      if (assetUrl === term) return 1
      if (version.includes(term)) return 2
      return 3
    })
  }
}

export const OverrideForm = ({
  name,
  state,
  defaultJs,
  overrideJs,
  toggleOpen,
  addOverride,
  removeOverride,
  enableOverride,
  disableOverride
}: OverrideFormProps) => {
  const { spaVersions, getSpaAssetUrl } = useSpaVersions(name)
  const [value, setOverrideVersion] = React.useState(overrideJs || '')

  const tadpoleSpa = spaVersions.find((spa) => getSpaAssetUrl(spa) === value)

  const filterItemsFn = makeFilterItemsFn(getSpaAssetUrl)

  return (
    <div className='grid gap-2 md:gap-4'>
      <TextInput label='Default URL' value={defaultJs || 'None'} readOnly />

      <AutoSuggestTextInput<SpaVersion>
        label='Override URL'
        helperText={tadpoleSpa ? `Version: ${tadpoleSpa.version}` : ''}
        itemToString={getSpaAssetUrl}
        items={spaVersions}
        filterItems={filterItemsFn}
        value={value}
        onChange={setOverrideVersion}
        renderItem={(spa: SpaVersion | null) =>
          !!spa && (
            <div key={spa.version}>
              <p>{spa.version || ''}</p>
              <p className='text-secondary type-subhead'>
                {getSpaAssetUrl(spa)}
              </p>
            </div>
          )
        }
        prefix={
          tadpoleSpa ? <CopyLink name={name} spaVersion={tadpoleSpa} /> : null
        }
        prefixVariant='iconButton'
        suffix={
          value ? (
            <IconButton
              icon={<CloseIcon aria-label='clear text' />}
              contained
              onClick={() => {
                setOverrideVersion('')
              }}
            />
          ) : spaVersions.length ? (
            <img
              height={24}
              width={24}
              src='https://cdn.toasttab.com/static/f6f13400bf1aa60334bc67215844fede6bdc5e81/projects/tadpole/logo.png'
              alt='tadpole spa versions'
            />
          ) : undefined
        }
        suffixVariant={
          value ? 'iconButton' : spaVersions.length ? 'icon' : undefined
        }
      />

      <div className='flex flex-row justify-end gap-4 md:gap-2'>
        {state === 'disabled' && (
          <Button
            variant='secondary'
            className='mr-auto'
            onClick={() => {
              enableOverride(name)
            }}
          >
            Enable
          </Button>
        )}

        {state === 'overridden' && (
          <Button
            variant='secondary'
            className='mr-auto'
            onClick={() => {
              disableOverride(name)
            }}
          >
            Disable
          </Button>
        )}

        <Button
          variant='link'
          onClick={() => {
            toggleOpen(name)
          }}
        >
          Cancel
        </Button>

        {(state === 'default' || value) && (
          <Button
            variant='primary'
            onClick={() => {
              addOverride(name, value)
              if (state === 'disabled') {
                enableOverride(name)
              }
            }}
            disabled={!value}
          >
            Apply
          </Button>
        )}

        {state !== 'default' && !value && (
          <Button
            onClick={() => {
              removeOverride(name)
            }}
          >
            Reset
          </Button>
        )}
      </div>
    </div>
  )
}

import * as React from 'react'
import { Heap } from './Heap'
import { ThemeVersion } from './ThemeVersion'

export interface ApplicationToolsProps {
  testId?: string | number
}

/**
 * ApplicationTools component
 * The tools for the application
 */
export const ApplicationTools = ({
  testId = 'ApplicationTools'
}: ApplicationToolsProps) => {
  return (
    <div data-testid={testId} className='space-y-6'>
      <Heap />
      <ThemeVersion />
    </div>
  )
}

import {
  AdministratorPermissions,
  CustomerPermissions
} from '@toasttab/check-permissions'

export type SupportedPermissions =
  | AdministratorPermissions
  | CustomerPermissions

interface PermissionsOptions<PermissionType extends SupportedPermissions> {
  label: string
  value: PermissionType
  subLabel: string
}

function transformPermissionsToOptions<
  PermissionsType extends SupportedPermissions
>(permissions: Object): PermissionsOptions<PermissionsType>[] {
  return Object.entries(permissions)
    .filter(
      ([name, bit]) => typeof name === 'string' && typeof bit === 'number'
    )
    .map(([name, bit]) => ({
      label: name as string,
      value: bit as PermissionsType,
      subLabel: String(bit)
    }))
}

export const AdministratorPermissionsOptions =
  transformPermissionsToOptions<AdministratorPermissions>(
    AdministratorPermissions
  )
export const CustomerPermissionsOptions =
  transformPermissionsToOptions<CustomerPermissions>(CustomerPermissions)

import React from 'react'
import { IconButton } from '@toasttab/buffet-pui-buttons'
import { AutorenewIcon } from '@toasttab/buffet-pui-icons'

export const RefreshButton = () => (
  <IconButton
    cropToIcon
    icon={<AutorenewIcon aria-label='Refresh' />}
    onClick={(e: React.MouseEvent) => {
      e.preventDefault()
      window.location.reload()
    }}
  />
)

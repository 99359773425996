import * as React from 'react'
import {
  castEvent,
  getPreferences,
  updatePreferences,
  resetPreferences
} from './storage'
import { Preferences } from './types'

interface PreferencesProviderProps {
  children: React.ReactNode
}

type InternalPreferences = [
  Preferences,
  React.Dispatch<React.SetStateAction<Preferences> | Partial<Preferences>>,
  () => void
]

// For future reference, the context is mainly needed here to synchronize the
// preferences state across components (e.g. Preferences and App), while
// `storage` events synchronise the state between tabs and windows, and persist
// the preferences. `storage` events are not dispatched in the same window.
const PreferencesContext = React.createContext<InternalPreferences>([
  getPreferences(),
  () => {},
  () => {}
])

export function PreferencesProvider({ children }: PreferencesProviderProps) {
  const [pref, setPref] = React.useState(getPreferences())

  function listener(e: StorageEvent) {
    const result = castEvent(e)
    if (result) {
      setPref((prev) => ({ ...prev, ...result }))
    }
  }

  React.useEffect(() => {
    window.addEventListener('storage', listener)

    return () => {
      window.removeEventListener('storage', listener)
    }
  }, [])

  const updatePref: InternalPreferences[1] = (patch) => {
    // Allows for partial update via `updatePreferences`
    setPref((previous) => {
      const actualPatch = typeof patch === 'function' ? patch(previous) : patch
      return updatePreferences(actualPatch)
    })
  }

  function resetPref() {
    const defaultPref = resetPreferences()
    setPref(defaultPref)
  }

  return (
    <PreferencesContext.Provider value={[pref, updatePref, resetPref]}>
      {children}
    </PreferencesContext.Provider>
  )
}

export function usePreferences() {
  return React.useContext(PreferencesContext)
}

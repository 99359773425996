import { getMountedApps } from 'single-spa'

function getLoadedParcels() {
  const bundles = []
  for (const [id] of System.entries()) {
    bundles.push(id)
  }
  return bundles
}

interface ImportMap {
  [key: string]: string
}
interface ImportMapResponse {
  imports: ImportMap
  scopes?: {}
}

export type State = 'default' | 'overridden' | 'disabled' | 'devliboverride'
export interface ToastImportMap {
  [key: string]: {
    defaultJs?: string
    overrideJs?: string
    css?: string
    state: State
    isMountedApp: boolean
  }
}
export interface ToastImport {
  name: string
  defaultJs?: string
  overrideJs?: string
  css?: string
  state: State
  isDirty: boolean // Needs refresh to take affect
  isOpen: boolean // Needs refresh to take affect
  isMountedApp: boolean // Is in getMountedApps or System.entries()
  isDuplicate: boolean // From parsing the different import maps
  // Handled by `wex-banquet-root`, uses the default map to resolve the module
  // if it fails with the overridden URL.
  hasOverrideFailed: boolean
}

// https://github.com/joeldenning/import-map-overrides/blob/main/docs/api.md#browser
declare global {
  interface Window {
    // @ts-ignore @toasttab/system-import shouldn't declare importMapOverrides
    // for the SPA consuming the package
    importMapOverrides: {
      enableUI(): void
      getOverrideMap(includeDisabled?: boolean): ImportMapResponse
      getDisabledOverrides(): string[]
      getExternalOverrideMap(): ImportMapResponse
      getDefaultMap(): Promise<ImportMapResponse>
      getCurrentPageMap(): Promise<ImportMapResponse>
      getNextPageMap(): Promise<ImportMapResponse>
      addOverride(key: string, value: string): void
      enableOverride(key: string): void
      disableOverride(key: string): void
      removeOverride(key: string): void
      resetOverrides(): void
    }
  }
}

type DevLibsMap = Record<string, (url: string) => string>

// set the dev versions of main libraries
// We will need to update this list and the logic with https://toasttab.atlassian.net/browse/WEX-2080
// Adding `@apollo/client`, and pointing to different URLs
const devLibs: DevLibsMap = {
  react: (url) => {
    // If it ends with `index.js` but not `dev.index.js`
    if (/index.js$/.test(url) && !/dev.index.js$/.test(url)) {
      // SystemJS version from jspm
      return url.replace('index.js', 'dev.index.js')
    } else {
      return url.replace('production.min', 'development')
    }
  },
  'react-dom': (url) => {
    // If it ends with `index.js` but not `dev.index.js`
    if (/index.js$/.test(url) && !/dev.index.js$/.test(url)) {
      // SystemJS version from jspm
      return url.replace('index.js', 'dev.index.js')
    } else {
      return url.replace('production.min', 'development')
    }
  },
  'single-spa': (url) => url.replace('single-spa.min.js', 'single-spa.dev.js'),
  // Apparently, there's an issue with our dev version of @sentry/browser,
  // causing Sentry to not be initialized and potentially crashing overridden
  // SPAs. Thus, the dev URL has been removed, but the key will remain in this
  // Map, as it used to know that @sentry/browser is an external library.
  // Link to development resource: https://github.com/toasttab/shared-static-assets/blob/master/projects/scripts/sentry/7.13.0/browser.js
  '@sentry/browser': (url) => url
}

function computeState(
  isOverridden: boolean,
  isDisabled: boolean,
  isDevOverride: boolean,
  isForcedOverride: boolean
): State {
  if (isDevOverride && !isForcedOverride) return 'devliboverride'
  if (isDisabled) return 'disabled'
  if (isOverridden) return 'overridden'
  return 'default'
}

function getValueFromState(state: State) {
  if (state === 'disabled') {
    return 0
  }
  if (state === 'overridden') {
    return 1
  }
  if (state === 'devliboverride') {
    return 3
  }
  return 2
}

export function enableUI(): void {
  window.importMapOverrides.enableUI()
}

function isLocalUrl(url: string): boolean {
  return url.includes('localhost') || url.includes('dev.eng.toastteam.com')
}

export function isAnyLocalhostOverrideActive(): boolean {
  const { imports } = window.importMapOverrides.getOverrideMap()
  return Object.values(imports).some(isLocalUrl)
}

export function addOverride(key: string, value: string): void {
  if (devLibs[key]) {
    // The forced override prevents the automatic override by the devLibs,
    // which you probably don't want if you manually override
    setForcedOverride(key, true)
  }

  window.importMapOverrides.addOverride(key, value)
}

export function removeOverride(key: string): void {
  removeForcedOverride(key)
  window.importMapOverrides.removeOverride(key)
}

export function enableOverride(key: string): void {
  window.importMapOverrides.enableOverride(key)
}

export function disableOverride(key: string): void {
  window.importMapOverrides.disableOverride(key)
}

export function resetOverrides(): void {
  Object.keys(localStorage)
    .filter((key) => key.startsWith('import-map-override-forced:'))
    .forEach((key) => localStorage.removeItem(key))

  window.importMapOverrides.resetOverrides()
}

export async function getDirtyOverrides(): Promise<string[]> {
  const { imports: currentImports } =
    await window.importMapOverrides.getCurrentPageMap()
  const { imports: nextImports } =
    await window.importMapOverrides.getNextPageMap()
  const disabledOverrides = window.importMapOverrides.getDisabledOverrides()

  return Object.keys(nextImports).filter(
    (key) =>
      currentImports[key] !== nextImports[key] &&
      !disabledOverrides.includes(key)
  )
}

const withSchemaAppended = (value: string) => {
  if (value.startsWith('//cdn')) {
    return `https:${value}`
  }
  return value
}

export async function getAllModules() {
  const mountedApps = getMountedApps()
  const loadedParcels = getLoadedParcels()
  const { imports: overriddenImports = {} } =
    window.importMapOverrides.getOverrideMap(true)
  const disabledImports = window.importMapOverrides.getDisabledOverrides()
  const { imports: allImports } =
    await window.importMapOverrides.getCurrentPageMap()
  const { imports: defaultImports } =
    await window.importMapOverrides.getDefaultMap()

  const allImportsAndOverrides = {
    ...overriddenImports,
    ...allImports
  }

  const externals = Object.keys(devLibs)
  return Object.entries(allImportsAndOverrides).reduce<ToastImportMap>(
    (acc, [key, value]) => {
      if (/\.css$/.test(key)) {
        const actualKey = key.substring(0, key.length - 4)
        return {
          ...acc,
          [actualKey]: {
            ...acc[actualKey],
            css: value
          }
        }
      } else {
        const isMountedApp =
          mountedApps.includes(key) ||
          loadedParcels.includes(withSchemaAppended(value))

        return {
          ...acc,
          [key]: {
            overrideJs: overriddenImports[key],
            defaultJs: defaultImports[key],
            isMountedApp,
            state: computeState(
              !!overriddenImports[key],
              disabledImports.includes(key),
              // Checks if the import is an external dependency, and if the
              // effective URL matches the dev substitution, based on the
              // default URL.
              externals.includes(key) &&
                value === devLibs[key](defaultImports[key]),
              isForcedOverride(key)
            )
          }
        }
      }
    },
    {}
  )
}

export function transformToastImportMap(
  importMap: ToastImportMap,
  dirtyOverrides: string[],
  open: string[],
  duplicates: string[],
  failedOverrides: string[]
) {
  const externals = Object.keys(devLibs)
  return Object.entries(importMap).reduce<ToastImport[]>(
    (acc, [key, value]) => {
      return acc.concat({
        name: key,
        isDirty: dirtyOverrides.includes(key),
        isOpen: open.includes(key),
        // Ignore external libraries for duplicate warning
        // Will be fixed by WEX: https://toasttab.atlassian.net/browse/WEX-2126
        isDuplicate: !externals.includes(key) && duplicates.includes(key),
        hasOverrideFailed: failedOverrides.includes(key),
        ...value
      })
    },
    []
  )
}

function sortString(first: string, second: string) {
  return first.localeCompare(second)
}

function sortTrueFirst(first: boolean, second: boolean) {
  if (first > second) {
    return -1
  } else if (first < second) {
    return 1
  }
  return 0
}

type SortChain = {
  getResult(): number
  andThenBy: (nextResult: () => number) => SortChain
}

function sortBy(result: number): SortChain {
  return {
    getResult() {
      return result
    },
    andThenBy(next) {
      if (result === 0) {
        return sortBy(next())
      }
      return sortBy(result)
    }
  }
}

export function sortByState(first: ToastImport, second: ToastImport) {
  return sortBy(sortTrueFirst(first.isDirty, second.isDirty))
    .andThenBy(
      () => getValueFromState(first.state) - getValueFromState(second.state)
    )
    .andThenBy(() => sortTrueFirst(first.isMountedApp, second.isMountedApp))
    .andThenBy(() => sortString(first.name, second.name))
    .getResult()
}

export function addDevLibOverrides() {
  async function doAddDevLibOverrides(notOverriddenMap: ImportMapResponse) {
    const defaultsMap = await window.importMapOverrides.getDefaultMap()

    Object.keys(notOverriddenMap.imports)
      .filter((libName) => devLibs[libName] && !isForcedOverride(libName))
      .forEach((libName) => {
        window.importMapOverrides.addOverride(
          libName,
          devLibs[libName](defaultsMap.imports[libName])
        )
      })
  }

  window.importMapOverrides.getCurrentPageMap().then(doAddDevLibOverrides)
}

export function removeAutoDevLibOverrides() {
  Object.keys(devLibs).forEach((devLib) => {
    if (!isForcedOverride(devLib)) {
      removeOverride(devLib)
    }
  })
}

function getImportMaps() {
  return Promise.all<ImportMapResponse>(
    Array.from(
      // Type supported by systemjs: https://github.com/systemjs/systemjs/blob/main/docs/import-maps.md
      document.querySelectorAll<HTMLScriptElement>(
        'script[type="systemjs-importmap"]'
      )
    )
      // Attribute added by the import-map-overrides: https://github.com/single-spa/import-map-overrides/blob/052d1a127bbecb27f000b8567225188324f9da6d/src/api/js-api.js#LL8C28-L8C54
      // So we skip overrides since they are present on purpose by the user
      .filter((script) => !script.hasAttribute('data-is-importmap-override'))
      .map((script) => {
        if (script.src) {
          return fetch(script.src).then((resp) => resp.json())
        } else {
          return Promise.resolve(
            JSON.parse(
              script.innerHTML.replaceAll('\\n', '').replaceAll('\\"', '"')
            )
          )
        }
      })
  )
}

async function getDuplicateEntries() {
  const importMaps = await getImportMaps()
  const flatImportMaps = importMaps
    .map((importMap) => Object.keys(importMap.imports))
    .flat()

  const modules = new Map<string, number>()
  const duplicates = new Set<string>()

  for (const module of flatImportMaps) {
    const count = modules.get(module) ?? 0
    modules.set(module, count + 1)
    if (count > 0) {
      duplicates.add(module)
    }
  }

  return [...duplicates.values()]
}

// This local storage entry should be cleared on page reload, we want the cache
// to live only within the page lifetime to avoid extra requests/computations.
const DUPLICATE_ENTRIES_LOCAL_STORAGE_KEY = 'duplicate-entries-from-import-maps'
export async function getCachedDuplicateEntries() {
  const cache = localStorage.getItem(DUPLICATE_ENTRIES_LOCAL_STORAGE_KEY)

  if (cache) {
    return JSON.parse(cache) as string[]
  }

  const duplicateEntries = await getDuplicateEntries()
  localStorage.setItem(
    DUPLICATE_ENTRIES_LOCAL_STORAGE_KEY,
    JSON.stringify(duplicateEntries)
  )
  return duplicateEntries
}

export function resetDuplicateEntriesCache() {
  localStorage.removeItem(DUPLICATE_ENTRIES_LOCAL_STORAGE_KEY)
}

function setForcedOverride(libName: string, value: boolean) {
  localStorage.setItem(getForcedLocalStorageKey(libName), value.toString())
}

function isForcedOverride(libName: string) {
  return localStorage.getItem(getForcedLocalStorageKey(libName)) === 'true'
}
function removeForcedOverride(libName: string) {
  localStorage.removeItem(getForcedLocalStorageKey(libName))
}

function getForcedLocalStorageKey(libName: string) {
  return `import-map-override-forced:${libName}`
}

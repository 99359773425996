import { useScreenSize, ScreenSize } from '@toasttab/use-screen-size'
import { usePreferences } from '../Preferences'

import type { ModalPin, ModalSize } from '@toasttab/buffet-pui-modal'

const smallModalSizes: ModalSize[] = ['sm', 'md', 'lg']
const smallModalPins: ModalPin[] = ['pin-center', 'pin-left', 'pin-right']

export function useIsModalWidthNarrow() {
  const [{ modalSize, modalPin }] = usePreferences()
  const screenSize = useScreenSize()
  return (
    screenSize < ScreenSize.MD &&
    smallModalPins.includes(modalPin) &&
    smallModalSizes.includes(modalSize)
  )
}

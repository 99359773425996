import { BanquetGraphiQL } from '@toasttab/banquet-graphql-playground'
import { Button } from '@toasttab/buffet-pui-buttons'
import { FullScreenIcon } from '@toasttab/buffet-pui-icons'
import * as React from 'react'

/**
 * GraphqlPlayground component
 */
export const GraphqlPlayground = (props: any) => {
  const extraHeaders = React.useMemo(
    () => ({
      // legacy headers - only used for backwards compatibility
      'toast-user-guid': props?.auth?.userInfo?.guid || ''
    }),
    [props?.auth?.userInfo?.guid]
  )
  return (
    <>
      <Button
        as='a'
        href='https://preprod.eng.toasttab.com/restaurants/admin/graphql'
        iconRight={<FullScreenIcon accessibility='decorative' />}
        target='_blank'
      >
        Try the new full page GraphQL Playground
      </Button>
      <BanquetGraphiQL extraHeaders={extraHeaders} />
    </>
  )
}

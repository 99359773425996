import * as React from 'react'
import { useSpaVersions } from './useSpaVersions'

export const OVERRIDE_SPA = 'overrideSpa'
export const OVERRIDE_URL = 'overrideUrl'
export const OVERRIDE_VERSION = 'overrideVersion'

export const useUrlParams = () => {
  const { spaVersions, setSpaName } = useSpaVersions()

  function cleanUpAndRefresh(params: URLSearchParams) {
    // remove the override url params
    params.delete(OVERRIDE_SPA)
    params.delete(OVERRIDE_URL)
    params.delete(OVERRIDE_VERSION)
    const baseUrl = `${window.location.origin}${window.location.pathname}`
    const intendedUrl = params.size > 0 ? `${baseUrl}?${params}` : baseUrl
    // refresh the page
    window.location.href = intendedUrl
  }

  // only run on load
  React.useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const spaName = params.get(OVERRIDE_SPA)
    const assetUrl = params.get(OVERRIDE_URL)
    const targetVersion = params.get(OVERRIDE_VERSION)

    if (spaName && (!!targetVersion || !!assetUrl)) {
      if (!!targetVersion) {
        setSpaName(spaName) // fetches spa versions
      } else {
        window.importMapOverrides.addOverride(spaName, assetUrl!)
        cleanUpAndRefresh(params)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // when only the adhoc or spa version is provided, apply its latest version
  React.useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const spaName = params.get(OVERRIDE_SPA)
    const targetVersion = params.get(OVERRIDE_VERSION)

    if (!!spaName && !!targetVersion && spaVersions?.length > 1) {
      const latestVersion = spaVersions.find(
        (spa) => spa.version === targetVersion
      )

      if (!!latestVersion) {
        window.importMapOverrides.addOverride(
          spaName,
          latestVersion?.assets[spaName]
        )
      }
      cleanUpAndRefresh(params) // clean up whether version is found or not
    }
  }, [spaVersions])

  return null
}

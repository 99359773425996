import * as React from 'react'
import { SpaVersion } from '@local/types'
import { OVERRIDE_SPA, OVERRIDE_URL, OVERRIDE_VERSION } from './useUrlParams'
import { IconButton } from '@toasttab/buffet-pui-buttons'
import { CopyIcon } from '@toasttab/buffet-pui-icons'
import { Tooltip } from '@toasttab/buffet-pui-tooltip'

type CopyLinkProps = {
  name: string
  spaVersion: SpaVersion
}
export function CopyLink({ name, spaVersion }: CopyLinkProps) {
  // generate a sharable link to override SPA with current version and location
  function getOverrideLink(event: React.MouseEvent) {
    const params = new URLSearchParams(window.location.search)

    params.append(OVERRIDE_SPA, name)
    if (event.shiftKey && !!spaVersion.assets[name]) {
      // allow for specific built version (like before) when user does shift+click
      params.append(OVERRIDE_URL, spaVersion.assets[name])
    } else if (!!spaVersion.assets.path) {
      params.append(OVERRIDE_URL, spaVersion.assets.path)
    } else {
      params.append(OVERRIDE_VERSION, spaVersion.version)
    }

    const overrideUrl = `${window.location.origin}${window.location.pathname}?${params}`
    navigator.clipboard.writeText(overrideUrl)
  }

  return (
    <Tooltip
      placement='bottom'
      content={() => (
        <div>
          <p>Copy version override link</p>
          <p>Shift-click for a specific build</p>
        </div>
      )}
    >
      <IconButton
        className='type-subhead text-link outline-none'
        icon={<CopyIcon />}
        onClick={getOverrideLink}
        size='sm'
        testId='copy-link'
      ></IconButton>
    </Tooltip>
  )
}

import { SentryErrorTesting } from '@toasttab/buffet-patterns-sentry-error-testing'
import * as React from 'react'

export interface ApplicationToolsProps {
  testId?: string | number
}

/**
 * ApplicationTools component
 * The tools for the application
 */
export const SentryTesting = ({
  testId = 'ApplicationTools'
}: ApplicationToolsProps) => {
  return (
    <div data-testid={testId} className='space-y-6'>
      <SentryErrorTesting />
    </div>
  )
}

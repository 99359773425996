import * as React from 'react'
import { Button } from '@toasttab/buffet-pui-buttons'
import {
  ArrowBackIcon,
  ArrowDownIcon,
  ArrowForwardIcon,
  ArrowUpIcon,
  BulletIcon
} from '@toasttab/buffet-pui-icons'
import { Label } from '@toasttab/buffet-pui-text-base'
import { LabelledToggleSwitch } from '@toasttab/buffet-pui-toggle-switches'
import { ToggleToken } from '@toasttab/buffet-pui-toggle-token'
import { usePreferences } from './Context'
import { Preferences as PreferencesType } from './types'

interface PinOption<T> {
  label: string
  value: T
  icon?: React.ReactNode
}

const MODAL_PIN_OPTIONS: PinOption<PreferencesType['modalPin']>[] = [
  { label: 'Left', value: 'pin-left', icon: <ArrowBackIcon /> },
  { label: 'Right', value: 'pin-right', icon: <ArrowForwardIcon /> },
  { label: 'Bottom', value: 'pin-bottom', icon: <ArrowDownIcon /> },
  { label: 'Top', value: 'pin-top', icon: <ArrowUpIcon /> },
  { label: 'Center', value: 'pin-center', icon: <BulletIcon /> }
]
const MODAL_SIZE_OPTIONS: PinOption<PreferencesType['modalSize']>[] = [
  { label: 'Small', value: 'sm' },
  { label: 'Medium', value: 'md' },
  { label: 'Large', value: 'lg' },
  { label: 'Extra large', value: 'xl' },
  { label: '2 Extra large', value: 'xxl' }
]
const TRIGGER_PIN_OPTIONS: PinOption<PreferencesType['triggerPin']>[] = [
  {
    label: 'Bottom left corner',
    value: 'bottom-left',
    icon: <ArrowBackIcon className='-rotate-45' />
  },
  {
    label: 'Bottom right corner',
    value: 'bottom-right',
    icon: <ArrowForwardIcon className='rotate-45' />
  }
]
export const IMPORT_MAP_OVERRIDE_OPTIONS = [
  {
    value: 'always',
    label: 'Always'
  },
  {
    value: 'auto',
    label: 'Auto',
    subLabel: 'Recommended'
  },
  {
    value: 'never',
    label: 'Never'
  }
] as const

// Could be ported over to Buffet
interface TokensGroupProps<T> {
  label: string
  name: string
  options: PinOption<T>[]
  value: T
  onValueChange(newValue: T): void
}
function TokensGroup<T>({
  label,
  name,
  options,
  value,
  onValueChange
}: TokensGroupProps<T>) {
  return (
    <fieldset className='grid grid-cols-1'>
      <legend className='type-default mb-4 font-semibold'>{label}</legend>
      <div className='inline-flex flex-row flex-wrap gap-2'>
        {options.map(({ label, value: optionValue, icon }) => (
          // `div` is needed in addition to the title to make sure the tokens
          // only take the size they need, and not what's available (I'm not
          // exactly sure what's the issue, but `flex-none` doesn't help).
          // Could use actual `Tooltip` rather than `title`
          <div title={label} key={label}>
            <ToggleToken
              name={name}
              size='sm'
              checked={value === optionValue}
              onChange={() => onValueChange(optionValue)}
            >
              {icon ?? label}
            </ToggleToken>
          </div>
        ))}
      </div>
    </fieldset>
  )
}

export function Preferences() {
  const [
    { modalPin, modalSize, triggerPin, showDevTools },
    setPreferences,
    resetPreferences
  ] = usePreferences()

  return (
    <div className='grid grid-cols-1 gap-4'>
      <TokensGroup
        label='Dev tools modal position'
        name='modal-pin'
        options={MODAL_PIN_OPTIONS}
        value={modalPin}
        onValueChange={(newValue) => setPreferences({ modalPin: newValue })}
      />
      <TokensGroup
        label='Dev tools modal size'
        name='modal-size'
        options={MODAL_SIZE_OPTIONS}
        value={modalSize}
        onValueChange={(newValue) => setPreferences({ modalSize: newValue })}
      />
      <TokensGroup
        label='Dev tools trigger position'
        name='trigger-pin'
        options={TRIGGER_PIN_OPTIONS}
        value={triggerPin}
        onValueChange={(newValue) => setPreferences({ triggerPin: newValue })}
      />
      <Label name='dev-tool'>
        Toast dev tools enabled (use{' '}
        <span className='bg-gray-0 type-subhead select-all rounded px-2 py-1 font-mono'>
          window.showDevTools()
        </span>{' '}
        in the console, and reload the page, to bring them back)
      </Label>
      <LabelledToggleSwitch
        name='dev-tools'
        labels={['No', 'Yes']}
        isActive={showDevTools}
        onChange={() => {
          setPreferences((previous) => ({
            ...previous,
            showDevTools: !previous.showDevTools
          }))
        }}
      />

      <Button onClick={resetPreferences}>Reset preferences to default</Button>
    </div>
  )
}

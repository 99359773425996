import { Preferences } from './types'

declare global {
  interface Window {
    resetDevToolsPreferences(): void
    showDevTools(): void
  }
}

const PREFERENCES_STORAGE_KEY = 'toast-dev-tools-preferences'
const DEFAULT_PREFERENCES: Preferences = {
  modalPin: 'pin-right',
  modalSize: 'lg',
  triggerPin: 'bottom-right',
  tabIndex: 0,
  showDevTools: true,
  devLibOverride: 'auto'
}

export function castEvent({
  key,
  newValue
}: StorageEvent): Partial<Preferences> | null {
  try {
    if (key !== PREFERENCES_STORAGE_KEY || !newValue) {
      return null
    }
    return JSON.parse(newValue)
  } catch (e) {
    console.warn(
      `Invalid "newValue" for the preferences storage: ${newValue}`,
      e
    )
    return null
  }
}

export function getPreferences(): Preferences {
  const current =
    JSON.parse(localStorage.getItem(PREFERENCES_STORAGE_KEY) ?? 'null') ??
    DEFAULT_PREFERENCES
  // In case we add new preferences in the future, we make sure that users
  // will pick them up without issues.
  return {
    ...DEFAULT_PREFERENCES,
    ...current
  }
}

export function updatePreferences(patchedPreferences: Partial<Preferences>) {
  const current = getPreferences()
  const updated = { ...current, ...patchedPreferences }
  localStorage.setItem(PREFERENCES_STORAGE_KEY, JSON.stringify(updated))
  return updated
}

export function resetPreferences() {
  const { tabIndex } = getPreferences()
  // We want to stay on the same tab when we reset the preferences
  return updatePreferences({ ...DEFAULT_PREFERENCES, tabIndex })
}
window.resetDevToolsPreferences = resetPreferences

function showDevTools() {
  updatePreferences({ showDevTools: true })
}
window.showDevTools = showDevTools

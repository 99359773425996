import * as React from 'react'
import { Badge, BadgeProps } from '@toasttab/buffet-pui-badge'
import { State } from './importMapApi'

interface BadgeStateProps extends Partial<BadgeProps> {
  state: State
}

type BadgeProperties = {
  color: BadgeProps['color']
  style: BadgeProps['badgeStyle']
}

function getBadgeColor(state: State): BadgeProperties {
  if (state === 'devliboverride') {
    return {
      color: 'warning',
      style: 'bold'
    }
  }
  if (state === 'disabled') {
    return {
      color: 'neutral0',
      style: 'bold'
    }
  }
  if (state === 'overridden') {
    return {
      color: 'success',
      style: 'bold'
    }
  }
  return {
    color: 'neutral0',
    style: 'subtle'
  }
}

export function BadgeState({ state, ...props }: BadgeStateProps) {
  const { color, style } = getBadgeColor(state)
  return (
    <Badge {...props} color={color} badgeStyle={style}>
      {state === 'devliboverride' ? 'Dev Lib' : state}
    </Badge>
  )
}

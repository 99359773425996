import * as React from 'react'
import { useLocalStorage } from 'react-use'
import { useBanquetProps } from 'banquet-runtime-modules'
import { Select } from '@toasttab/buffet-pui-select'

export interface ThemeVersionProps {
  testId?: string | number
}

export const THEME_VERSION_OVERRIDE_KEY = 'TOAST-isNextBuffetTheme'

type OverrideValue = 'default' | 'true' | 'false'

const overrideOptions = [
  {
    label: 'Default',
    value: 'default'
  },
  {
    label: 'Next',
    value: true
  },
  {
    label: 'Current',
    value: false
  }
]

/**
 * ApplicationTools component
 * The tools for the application
 */
export const ThemeVersion = ({
  testId = 'ThemeVersion'
}: ThemeVersionProps) => {
  const [overrideValue, setOverrideValue, unsetOverrideValue] =
    useLocalStorage<OverrideValue>(THEME_VERSION_OVERRIDE_KEY)

  const { isNextBuffetTheme } = useBanquetProps()
  const isDisabled = typeof isNextBuffetTheme === 'undefined'

  return (
    <article data-testid={testId} className='space-y-4'>
      <header>
        <h3 className='type-default font-semibold'>Buffet theme</h3>
        <p className='type-caption'>
          If the page you are viewing is theme-enabled and using the default
          "props.isNextBuffetTheme" condition, you can use this control to
          override that default for testing. Note that changing this value will
          trigger a page reload.
        </p>
      </header>
      <Select
        label='Theme version override'
        hideLabel
        options={overrideOptions}
        value={overrideValue === undefined ? 'default' : overrideValue}
        onChange={(value: OverrideValue) => {
          if (value === 'default') {
            unsetOverrideValue()
          } else {
            setOverrideValue(value)
          }
          window.location.reload()
        }}
        disabled={isDisabled}
      />
      {isDisabled && (
        <p className='type-caption'>
          <strong className='font-semibold'>Note:</strong> This option is
          disabled because the root SPA for this page does not include
          "props.isNextBuffetTheme" in the banquet props
        </p>
      )}
    </article>
  )
}

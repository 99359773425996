import { resetDuplicateEntriesCache } from '@local/import-map-tool'
import { banquetSingleSpaReact, BanquetProps } from 'banquet-runtime-modules'
import { AppSetup } from './components/App'
import { ThemeManager } from '@toasttab/banquet-theme-manager'
import { themes } from '@local/themes'
import './index.css'

// Run on SPA mount to keep the cache active only within the page lifetime
resetDuplicateEntriesCache()

const reactLifecycles = banquetSingleSpaReact({
  cssScope: 'data-devt',
  rootComponent: AppSetup,

  sentry: {
    publicKey: '1afab818f69e4eb0828fd82ee7cfcf29@o37442',
    projectId: '4505505289273344',
    releaseVersion: process.env.PKG_VERSION
  },

  theme: new ThemeManager((props: BanquetProps) => {
    if (props.isNextBuffetTheme) {
      return themes.next
    }
    return themes.current
  })
})

export const bootstrap = reactLifecycles.bootstrap
export const mount = reactLifecycles.mount
export const unmount = reactLifecycles.unmount

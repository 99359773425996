import * as React from 'react'
import { Select } from '@toasttab/buffet-pui-select'
import {
  CheckCircleSelectedIcon,
  CloseCircleIcon
} from '@toasttab/buffet-pui-icons'
import { SupportedPermissions } from './permissionsOptions'

interface PermissionTesterProps<PermissionType extends SupportedPermissions> {
  label: string
  options: { label: string; value: PermissionType; subLabel: string }[]
  check(bit: PermissionType): boolean
}

function PermissionIcon({ hasPermission }: { hasPermission: boolean }) {
  if (hasPermission) {
    return <CheckCircleSelectedIcon className='text-success' />
  }
  return <CloseCircleIcon className='text-error' />
}

export function PermissionTester<PermissionType extends SupportedPermissions>({
  label,
  options,
  check
}: PermissionTesterProps<PermissionType>) {
  const [selectedPermission, setSelectedPermission] =
    React.useState<PermissionType>()
  const hasPermission =
    selectedPermission !== undefined ? check(selectedPermission) : false

  return (
    <Select
      enableSearch
      label={
        <div className='flex flex-row gap-4'>
          {label}
          {selectedPermission !== undefined && (
            <PermissionIcon hasPermission={hasPermission} />
          )}
        </div>
      }
      value={selectedPermission}
      options={options}
      onChange={setSelectedPermission}
    />
  )
}

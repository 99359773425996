import { useSnackBar } from '@toasttab/buffet-pui-snackbars'
import {
  getModulesWithFailedOverride,
  MODULES_WITH_FAILED_OVERRIDE_STORAGE_KEY
} from '@toasttab/system-import'
import * as React from 'react'

interface Props {
  onNewModulesFailedOverride?(newModules: string[]): void
}
/**
 * Retrieves all the modules with failed override, listens and triggers the
 * `onNewModulesFailedOverride` callback to new ones.
 */
export function useModulesWithFailedOverride({
  onNewModulesFailedOverride = () => {}
}: Props = {}) {
  const [previousFailedModules, setPreviousFailedModules] = React.useState<
    string[]
  >([])
  const [failedModules, setFailedModules] = React.useState([
    ...getModulesWithFailedOverride()
  ])

  function listener({ key, newValue }: StorageEvent) {
    if (key !== MODULES_WITH_FAILED_OVERRIDE_STORAGE_KEY || !newValue) {
      return
    }
    setFailedModules((prev) => prev.concat(JSON.parse(newValue)))
  }

  React.useEffect(() => {
    window.addEventListener('storage', listener)

    return () => {
      window.removeEventListener('storage', listener)
    }
  }, [])

  React.useEffect(() => {
    if (previousFailedModules.length === failedModules.length) return
    const newModules =
      previousFailedModules.length === 0
        ? failedModules
        : failedModules.filter((spa) => !previousFailedModules.includes(spa))
    setPreviousFailedModules(failedModules)
    onNewModulesFailedOverride(newModules)
  }, [failedModules, previousFailedModules, onNewModulesFailedOverride])

  return failedModules
}

/**
 * Calls `useModulesWithFailedOverride` and shows a snackbar when new modules
 * with failed override are detected.
 */
export function useModulesWithFailedOverrideWarning() {
  const { showErrorSnackBar } = useSnackBar()
  useModulesWithFailedOverride({
    onNewModulesFailedOverride(newModules) {
      showErrorSnackBar(
        `The following SPAs failed to load, please check the Import Map in the Toast Dev Tools: ${newModules.join(
          ', '
        )}.`
      )
    }
  })
}

import { ToastImport } from './importMapApi'

export enum SupportedAttributes {
  DUPLICATE = 'duplicate',
  FAILED_OVERRIDE = 'failed'
}

function termsToFilteringAttributes(terms: string[]) {
  return function (module: ToastImport) {
    return terms.every((term) => {
      if (term === SupportedAttributes.DUPLICATE) {
        return module.isDuplicate
      }
      if (term === SupportedAttributes.FAILED_OVERRIDE) {
        return module.hasOverrideFailed
      }
      return module.name.includes(term)
    })
  }
}

export function filterModules(modules: ToastImport[], searchTerms: string) {
  const terms = searchTerms.toLocaleLowerCase().split(' ')
  const byAttribute = termsToFilteringAttributes(terms)
  return modules.filter(byAttribute)
}

type CriteriaFn<T> = (item: T) => string | number

export const sortBy = <T extends any>(list: T[], fn: CriteriaFn<T>) => {
  let index = 0

  return list
    .map((item) => ({ item, index: index++, criteria: fn(item) }))
    .sort((left, right) => {
      const a = left.criteria
      const b = right.criteria
      if (a !== b) {
        if (a > b || a === undefined) return 1
        if (a < b || b === undefined) return -1
      }
      return left.index - right.index
    })
    .map((value) => value.item)
}

export const getTokens = (inputStr: string | undefined): string[] => {
  if (!inputStr) return []

  return inputStr
    .split(' ')
    .map((str) => str.trim())
    .filter(Boolean)
}

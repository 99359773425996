import * as React from 'react'
import { gql, useLazyQuery } from '@apollo/client'
import {
  Environment,
  QuerySpaVersionsArgs,
  SpaVersion,
  SpaVersionsQuery
} from '@local/types'

const LOCAL_DEV_FLAG = '__LOCAL_DEV_BRANCH__'
const localDevBranch = {
  spaId: '',
  version: 'Local dev (with default port)',
  assets: {
    path: 'https://dev.eng.toastteam.com:9990/bundle.js'
  },
  branch: LOCAL_DEV_FLAG
}

export const GET_SPA_VERSIONS = gql`
  query SpaVersions($spaId: ID!, $environment: Environment!) {
    spaVersions(spaId: $spaId, environment: $environment) {
      version
      assets
      commitAuthor
      commitDate
      commitMsg
    }
  }
`
export const GET_ADHOC_VERSIONS = gql`
  query AdhocVersions($spaId: ID!) {
    adhocVersions(spaId: $spaId) {
      version
      assets
      commitAuthor
      commitDate
      commitMsg
      branch
    }
  }
`

// fetches SPA versions when name is provided
export function useSpaVersions(name?: string) {
  const [spaName, setSpaName] = React.useState(name || '')

  const [queryMainVersions, { data: mainVersionsResponse }] = useLazyQuery<
    SpaVersionsQuery,
    QuerySpaVersionsArgs
  >(GET_SPA_VERSIONS, {
    variables: {
      spaId: spaName,
      environment: Environment.Preprod
    }
  })

  const [queryAdhocVersions, { data: adhocVersionsResponse }] = useLazyQuery<
    { adhocVersions: SpaVersion[] },
    QuerySpaVersionsArgs
  >(GET_ADHOC_VERSIONS, {
    variables: {
      spaId: spaName
    }
  })

  const spaVersions: Array<SpaVersion> = [
    localDevBranch,
    // main versions
    ...(mainVersionsResponse?.spaVersions.map((version) => ({
      spaId: spaName,
      ...version
    })) || []),
    // adhoc versions
    ...getLatestAdhocVersions(adhocVersionsResponse?.adhocVersions)
  ]

  React.useEffect(() => {
    if (!!spaName) {
      queryMainVersions()
      queryAdhocVersions()
    }
  }, [spaName, queryAdhocVersions, queryMainVersions])

  // util
  function getSpaAssetUrl(version: SpaVersion | null): string {
    if (version?.branch === LOCAL_DEV_FLAG) {
      return version.assets.path
    }
    return version?.assets[spaName] || ''
  }

  return {
    getSpaAssetUrl,
    setSpaName,
    spaName,
    spaVersions
  }
}

// helpers
function getLatestAdhocVersions(allVersions: Array<SpaVersion> = []) {
  const filteredVersions: Array<SpaVersion> = []

  const branchMap = allVersions.reduce((accumulator, item) => {
    if (item.branch) {
      const branchName = item.branch
      const versions = accumulator.get(branchName) ?? []
      versions.push(item)
      accumulator.set(branchName, versions)
    }
    return accumulator
  }, new Map<string, SpaVersion[]>())

  for (const [_, versions] of branchMap) {
    const latestVersion = versions.reduce((a, b) =>
      (a.commitDate ?? 0) > (b.commitDate ?? 0) ? a : b
    )
    filteredVersions.push({
      ...latestVersion,
      version: latestVersion.branch || latestVersion.version
    })
  }

  return filteredVersions
}

export const clearHeapCookies = () => {
  const heapCookieToDelete = /^_hp2_/
  document.cookie
    .split(/\s*;\s*/)
    .map(function (s) {
      return s.replace(/=.*/, '')
    })
    .filter(function (n) {
      return heapCookieToDelete.test(n)
    })
    .forEach(function (n) {
      document.cookie =
        n +
        '=;expires=Thu, 01 Jan 1970 00:00:00 UTC;domain=.toasttab.com;path=/'
    })
}

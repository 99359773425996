import React, { useState } from 'react'
import { Button } from '@toasttab/buffet-pui-buttons'
import { AddModuleForm } from './AddModuleForm'
import { RefreshButton } from '../RefreshButton'
import { Select } from '@toasttab/buffet-pui-select'
import {
  IMPORT_MAP_OVERRIDE_OPTIONS,
  usePreferences
} from '@local/preferences-tool'
import { InfoTooltip } from '@toasttab/buffet-pui-tooltip'

interface ActionsProps {
  addOverride: (key: string, value: string) => void
  resetOverrides: () => void
  isAnyModuleDirty: boolean
}

export function Actions({
  addOverride,
  resetOverrides,
  isAnyModuleDirty
}: ActionsProps) {
  const [isFormOpen, setFormOpen] = useState(false)
  const [{ devLibOverride }, setPreferences] = usePreferences()

  return (
    <div>
      <div className='flex flex-wrap items-end justify-between gap-2'>
        <div className='flex items-end'>
          <Select
            label='Dev lib override'
            name='devLibOverridePreference'
            options={IMPORT_MAP_OVERRIDE_OPTIONS.concat()} // concat to remove readonly type error
            value={devLibOverride}
            onChange={(devLibOverride) => setPreferences({ devLibOverride })}
          />
          <InfoTooltip>
            <b>Always</b>: always use development versions of external
            libraries.
            <br />
            <b>Auto</b>: use development versions if any SPA is overridden with
            a local URL.
            <br />
            <b>Never</b>: never use development versions of external libraries.
          </InfoTooltip>
        </div>
        <div className='flex flex-wrap items-center gap-2'>
          <div className='mr-2'>{isAnyModuleDirty && <RefreshButton />}</div>
          <Button variant='secondary' onClick={() => setFormOpen(!isFormOpen)}>
            {isFormOpen ? 'Close form' : 'Add module'}
          </Button>
          {/* <Button variant='secondary'>Add Import Map</Button> */}
          <Button
            variant='destructive-secondary'
            onClick={() => {
              resetOverrides()
            }}
          >
            Reset overrides
          </Button>
        </div>
      </div>
      <AddModuleForm
        addOverride={addOverride}
        isOpen={isFormOpen}
        closeForm={() => setFormOpen(false)}
      />
    </div>
  )
}
